import React, { createContext, FC, ReactNode } from 'react';

export const GlobalContext = createContext({});

const GlobalContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return <GlobalContext.Provider value={{}}>{children}</GlobalContext.Provider>;
};

export default GlobalContextProvider;
