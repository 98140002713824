// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { createTheme } from '@mui/material';

import Gilroy from '../../assets/font/Gilroy-Medium.woff2';

export const lightThemeColors = {
  grey: {
    1: '#333333',
    2: '#414141',
    3: '#4F4F4F',
    4: '#5D5D5D',
    5: '#6B6B6B',
    6: '#797979',
    7: '#878787',
    8: '#959595',
    9: '#A3A3A3',
    10: '#B1B1B1',
    11: '#BFBFBF',
    12: '#CDCDCD',
    13: '#DBDADB',
    14: '#E9E8E9',
    15: '#F8F7F8',
    16: '#FFFFFF',
  },
  green: {
    1: '#ECFFC7',
    2: '#DFFEA0',
    3: '#9FB688',
  },
  blue: {
    1: '#A4DDFF',
    2: '#7BCEFF',
    3: '#7A9AA8',
    4: '#789ABD',
  },
  red: {
    1: '#FFB0CD',
    2: '#FF8DB4',
    3: '#9B6A7D',
  },
  purple: {
    1: '#BEADEE',
    2: '#B5A4D9',
    3: '#A58CCE',
    4: '#9273A5',
  },
  yellow: {
    2: '#FFE28F',
  },
};

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    ...lightThemeColors,
    primary: {
      main: lightThemeColors.blue['2'],
    },
  },

  typography: {
    fontFamily: 'Gilroy, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          outline: 'none',
          '&:hover': { outline: 'none' },
          '&:active': { outline: 'none' },
          '&:focus-visible': { outline: 'none' },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 400;
          src: local('Gilroy'), local('Gilroy Regular'), url(${Gilroy}) format('woff2');
        }
      `,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          input: {
            padding: '10px 5px',
            color: lightThemeColors.grey['2'],
            backgroundColor: lightThemeColors.grey['15'],
            borderRadius: '4px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: lightThemeColors.grey['10'],
            borderWidth: '2px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: lightThemeColors.grey['10'],
            borderWidth: '2px',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: lightThemeColors.blue['3'],
            borderWidth: '2px',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: lightThemeColors.blue['2'],
        },
      },
    },
  },
});

export default lightTheme;
