import axios from './axios';

const openPosition = async (positionData: {
  side: 'SELL' | 'BUY';
  quantity: number;
  stopLoss: number;
  takeProfit: number;
}) => {
  const { data } = await axios.post('/binance/open-position', positionData);

  return data;
};

export default openPosition;
