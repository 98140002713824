import React, { FC } from 'react';

import Router from './router';

const App: FC = () => {
  // return (
  //   <WithBackgroundPattern>
  //     <Router />
  //   </WithBackgroundPattern>
  // );

  return <Router />;
};
export default App;
