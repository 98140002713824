import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AppPage from '../app/app.page';

import NavigateToDefault from './NavigateToDefault';

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<AppPage />} />
      <Route path="/*" element={<NavigateToDefault />} />
    </Routes>
  );
};

export default Router;
