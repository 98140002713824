import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { FC, ReactNode } from 'react';

import darkTheme from './dark.theme';
import lightTheme from './light.theme';

interface ThemeContextProviderProps {
  children: ReactNode;
}

const ThemeContextProvider: FC<ThemeContextProviderProps> = ({ children }) => {
  const theme = {
    dark: darkTheme,
    light: lightTheme,
    lol: lightTheme,
  };

  return (
    <ThemeProvider theme={theme['dark']}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeContextProvider;
