import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

const SetToken = () => {
  const [input, setInput] = useState('');

  const onSubmit = () => {
    localStorage.setItem('token', input);

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  return (
    <Stack
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'blue.1',
        px: '10px',
      }}
    >
      <Typography
        sx={{
          mt: '20px',
          textAlign: 'center',
        }}
      >
        Enter token
      </Typography>

      <TextField value={input} onChange={(e) => setInput(e.target.value)} />
      <Button
        variant="contained"
        sx={{
          mt: '10px',
        }}
        disabled={!input}
        onClick={onSubmit}
      >
        Submit
      </Button>
    </Stack>
  );
};

export default SetToken;
