import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import AppContextProvider from './AppContext';
import ThemeContextProvider from './common/theme/theme.context';
import GlobalContextProvider from './GlobalContext';
import i18n from './i18n/config';
import './styles/index.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).global = window;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <BrowserRouter>
        <AppContextProvider>
          <I18nextProvider i18n={i18n}>
            <GlobalContextProvider>
              <App />
            </GlobalContextProvider>
          </I18nextProvider>
        </AppContextProvider>
      </BrowserRouter>
    </ThemeContextProvider>
  </React.StrictMode>,
);
