import { Button, Input, Stack, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import openPosition from '../api/openPosition';
import logoImg from '../assets/logo.png';
import useCalculations from '../hooks/useCalculations';

import SetToken from './set-token';

export const OPEN_FEE_COEF = 0.0005;
export const CLOSE_FEE_COEF = 0.0002;

const AppPage = () => {
  const [appLoading, setAppLoading] = useState(true);
  const [appToken, setAppToken] = useState('');

  const [currentPrice, setCurrentPrice] = useState(0);
  const [prevPrice, setPrevPrice] = useState(0);

  const [quantity, setQuantity] = useState(1);
  const [profit, setProfit] = useState(0);
  const [loss, setLoss] = useState(0);

  const [placingPosition, setPlacingPosition] = useState(false);

  const { takeProfitShort, takeProfitLong, stopLossShort, stopLossLong } =
    useCalculations(currentPrice, profit, loss, quantity);

  const setters = {
    profit: setProfit,
    loss: setLoss,
    quantity: setQuantity,
  };

  useEffect(() => {
    const profit = localStorage.getItem('profit');
    const loss = localStorage.getItem('loss');
    const token = localStorage.getItem('token');

    const numProfit = parseFloat(profit ?? '');
    const numLoss = parseFloat(loss ?? '');

    const realProfit = isNaN(numProfit) ? 0 : numProfit;
    const realLoss = isNaN(numLoss) ? 0 : numLoss;

    setProfit(realProfit);
    setLoss(realLoss);
    setAppToken(token ?? '');

    setAppLoading(false);
  }, []);

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber;
    const name = e.target.name;

    if (name.toString() in setters) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setters[name.toString()](value);
      localStorage.setItem(name.toString(), value.toString());
    }
  };

  const [socket, setSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    if (socket) return;

    const ws = new WebSocket('wss://fstream.binance.com/ws/btcusdt@aggTrade');

    ws.onmessage = (data) => {
      const { data: info } = data;
      const obj = JSON.parse(info);

      setCurrentPrice((p) => {
        setPrevPrice(Number(p));

        // return Number(34603);
        return Number(obj.p);
      });
    };

    setSocket(ws);
  }, []);

  const onQChange = (e: ChangeEvent<HTMLInputElement>) => {
    const num = e.target.valueAsNumber;

    setQuantity(num);
  };

  const notValid = useMemo(
    () => isNaN(quantity) || isNaN(loss) || isNaN(profit),
    [quantity, loss, profit],
  );

  if (appLoading) return null;

  if (!appLoading && !appToken) {
    return <SetToken />;
  }

  return (
    <Stack
      sx={{
        maxWidth: '320px',
        mx: 'auto',
        mt: '10px',
      }}
    >
      <img
        src={logoImg}
        alt=""
        style={{
          maxWidth: '200px',
        }}
      />
      <Stack
        sx={{
          mb: '10px',
        }}
      >
        <Typography
          sx={{
            fontSize: '58px',
            color: currentPrice >= prevPrice ? 'green.2' : 'red.2',
          }}
        >
          {currentPrice}
        </Typography>
      </Stack>

      <Stack>
        <Stack
          direction="row"
          gap="10px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Quantity</Typography>
          <Input
            type="number"
            inputProps={{
              min: 0.1,
              max: 10,
              step: 0.1,
            }}
            value={quantity}
            onChange={onQChange}
          />
        </Stack>

        <Stack
          direction="row"
          gap="10px"
          alignItems="center"
          sx={{
            mt: '20px',
          }}
          justifyContent="space-between"
        >
          <Typography>Profit</Typography>
          <Input
            type="number"
            name="profit"
            inputProps={{
              min: 1,
              max: 10000,
              step: 1,
            }}
            sx={{
              width: '80px',
            }}
            value={profit}
            onChange={onInput}
          />
        </Stack>

        <Stack
          direction="row"
          gap="10px"
          sx={{
            mt: '20px',
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Loss</Typography>
          <Input
            type="number"
            name="loss"
            inputProps={{
              min: 1,
              max: 10000,
              step: 1,
            }}
            sx={{
              width: '80px',
            }}
            value={loss}
            onChange={onInput}
          />
        </Stack>

        <Stack
          sx={{
            mt: '20px',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
            }}
          >
            Take Profit
          </Typography>
          <Stack
            direction="row"
            gap="10px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{takeProfitLong.toFixed(2)}</Typography>
            <Typography>{takeProfitShort.toFixed(2)}</Typography>
          </Stack>
        </Stack>

        <Stack
          sx={{
            mt: '20px',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
            }}
          >
            Stop Loss
          </Typography>
          <Stack
            direction="row"
            gap="10px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{stopLossLong.toFixed(2)}</Typography>
            <Typography>{stopLossShort.toFixed(2)}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap="20px"
        mt="20px"
      >
        <Button
          variant="contained"
          color="success"
          disabled={placingPosition || notValid}
          sx={{
            width: '300px',
            height: '70px',
          }}
          onClick={() => {
            setPlacingPosition(true);
            openPosition({
              side: 'BUY',
              quantity,
              takeProfit: takeProfitLong,
              stopLoss: stopLossLong,
            }).finally(() => setPlacingPosition(false));
          }}
        >
          LONG
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '300px',
            height: '70px',
          }}
          disabled={placingPosition || notValid}
          color="error"
          onClick={() => {
            setPlacingPosition(true);
            openPosition({
              side: 'SELL',
              quantity,
              takeProfit: takeProfitShort,
              stopLoss: stopLossShort,
            }).finally(() => setPlacingPosition(false));
          }}
        >
          SHORT
        </Button>
      </Stack>
    </Stack>
  );
};

export default AppPage;
