import { useEffect, useState } from 'react';

const OPEN_FEE_COEF = 0.0005;
const CLOSE_FEE_COEF = 0.0002;

const useCalculations = (
  price: number,
  profit: number,
  loss: number,
  quantity: number,
) => {
  const [takeProfitLong, setTakeProfitLong] = useState(0);
  const [stopLossLong, setStopLossLong] = useState(0);
  const [noLossLong, setNoLossLong] = useState(0);

  const [takeProfitShort, setTakeProfitShort] = useState(0);
  const [stopLossShort, setStopLossShort] = useState(0);
  const [noLossShort, setNoLossShort] = useState(0);

  useEffect(() => {
    let newTpLong = price + price * OPEN_FEE_COEF + profit;

    newTpLong += newTpLong * CLOSE_FEE_COEF;

    let newSLLong = price + price * OPEN_FEE_COEF - loss;
    newSLLong += newSLLong * CLOSE_FEE_COEF;

    setTakeProfitLong(newTpLong);
    setStopLossLong(newSLLong);
    setNoLossLong(price + price * (OPEN_FEE_COEF + CLOSE_FEE_COEF));

    let newTpShort = price - price * OPEN_FEE_COEF - profit;

    newTpShort -= newTpShort * CLOSE_FEE_COEF;

    let newSLShort = price - price * OPEN_FEE_COEF + loss;
    newSLShort -= newSLShort * CLOSE_FEE_COEF;

    setTakeProfitShort(newTpShort);
    setStopLossShort(newSLShort);
    setNoLossShort(price - price * (OPEN_FEE_COEF + CLOSE_FEE_COEF));
  }, [price, profit, loss, quantity]);

  return {
    takeProfitLong,
    stopLossLong,
    noLossLong,
    takeProfitShort,
    stopLossShort,
    noLossShort,
  };
};

export default useCalculations;
